<template>
  <div id="app">

    <div style="z-index:100;">
      <FormPage />
    </div>

  </div>
</template>

<script>
import FormPage from './components/FormPage.vue'

export default {
  name: 'App',
  components: {
    FormPage
  },
  data: function () {
    return {
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        speed: 1,
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          }
        }
      },
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
