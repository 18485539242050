import Vue from 'vue'
import App from './App.vue'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.config.productionTip = false

Vue.use(LottieVuePlayer);

import "@/assets/css/normalize.css"
import "@/assets/css/skeleton.css"
import "@/assets/style.scss"

new Vue({
  render: h => h(App),
}).$mount('#app')
