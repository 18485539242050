<template>
  <div class="formpage__container">
    <div class="error-msg" v-show="alert">
    <div>
      Vul alle velden in!
    </div>

    <div>
      <a v-on:click="alert = false">X</a>
    </div>

  </div>

    <div class="happy-msg" v-show="happy_alert">
    <div>
      Bedankt voor het aanmelden! Controleer je email voor een bevesteging
    </div>

    <div>
      <a v-on:click="happy_alert = false">X</a>
    </div>

  </div>
    <transition name="fade">
    <div v-if="showAnimation" class="animation__container">
        <lottie-vue-player :src="`https://assets3.lottiefiles.com/packages/lf20_87J1Pt.json`"
                           :theme="options.theme"
                           :player-size="options.playerSize"
                           :player-controls="false"
                           :speed="options.speed"
                           style="width: 100%; height:250px; margin: 0;position: absolute; top: 0;"
                           >
        </lottie-vue-player>
    </div>
    </transition>

    <transition name="fade">
    <div class="page__container" v-if="!showAnimation">
      <div class="page__image">
        <img src="../assets/feest.jpg">
      </div>

      <div class="information">
        <h2>Aanmelden:</h2>

        <div class="form__container">


          <div>
            <input type="text" placeholder="Naam" v-model="form.name">
          </div>
          <div>
            <input type="text" placeholder="Email" v-model="form.email">
          </div>
          <div>
            <select name="BU" id="BU" v-model="form.bu">
              <option value="" disabled selected>Werkt bij</option>
              <option value="Int">International</option>
              <option value="RD">B&C</option>
              <option value="TRD">Trendiy</option>
              <option value="BTR">BTR</option>
              <option value="NE">NE</option>
            </select>
          </div>
          <div>
            <select name="Dieet" id="dieet" v-model="form.diet">
              <option value="" disabled selected>Dieet voorkeur</option>
              <option value="geen">geen</option>
              <option value="vega">vega</option>
              <option value="glutenvrij">glutenvrij</option>
              <option value="anders">anders</option>
            </select>
            <div>
              <input type="text" placeholder="Opmerking" v-model="form.note">
            </div>
             <div>
              <fieldset class="komt__container">
                <div class="select__container">
                  <input type="radio" id="komt" name="komt" value="komt" v-model="form.komt" checked >
                  <label for="komt">Ik kom</label>
                </div>
                <div class="select__container">
                  <input type="radio" id="komt_niels" name="komt" value="niet" v-model="form.komt" >
                  <label for="komt_niet">Ik kom niet</label>
                </div>
              </fieldset>
            </div>
          </div>
          <button v-on:click="handleRegistration" v-if="button">aanmelden</button>
        </div>
      </div>

    </div>
    </transition>

  </div>
</template>

<script>

export default {
  name: 'FormPage',
  data: function () {
    return {
        options: {
          minimizable: false,
          playerSize: "standard",
          backgroundColor: '#fff',
          backgroundStyle: 'color',
          speed: 1,
          theme: {
            controlsView: "standard",
            active: "light",
            light: {
              color: '#3D4852',
              backgroundColor: '#fff',
              opacity: '0.7',
            },
            dark: {
              color: '#fff',
              backgroundColor: '#202020',
              opacity: '0.7',
            }
        }
      },
        showAnimation: true,
        form:{
          name: "",
          email: "",
          bu: "",
          diet: "",
          note: "",
          komt: "komt",
        },
        alert: false,
        happy_alert: false,
        button: true
    }
  },

  methods: {
    handleRegistration: function () {
      let validated = true;
      
      for (let prop in this.form) {
        if(prop !== "note" && this.form[prop] === ""){
          validated = false;
        }
      }

      if(!validated){
        this.alert = true
        return
      }else{
        this.button = false
        this.sendData();
      }
    },

    sendData: function () {
      fetch('https://bece-50-jaar-api.azurewebsites.net/api/attendees', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        name: this.form.name,
        email: this.form.email,
        bu: this.form.bu,
        diet: this.form.diet,
        note: this.form.note,
        coming: this.form.komt,
        })
      }).then(() => {this.happy_alert = true})
    }
  },
  mounted() {
    setTimeout(function (scope) {
      scope.showAnimation = false;
    }, 250, this);

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .information{
    margin: 12px 12px 12px 36px
  }
  .formpage__container{
    margin-top: 100px;
  }
  .page__container{
    width: 850px;
    height: 470px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 8px;
  }

  .page__container img{
    width: 470px;
    height: auto;
  }

  .form__container{
    width: 300px;
    display: flex;
    flex-flow: column;
    margin: auto 0 auto 0 ;
    padding: 12px 12px 0px 12px;


  }
  .form__container input[type=text],select{
    width: 90%;
  }

  .animation__container{
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    position: relative;
  }

  @media only screen and (max-width: 800px) {
    .page__image {
      display: none;
    }

    .page__container {
      justify-content: space-around;
      width: 90%;
    }
    .information{
      margin: 0;
    }

    .form__container input[type=text],select, button{
      width: 100%;
    }

    button{
      margin: auto;
    }

   .information h2{
      margin-top: 12px;
    }
  }
</style>
